import React, { Component, createContext, useEffect } from 'react';
import Loadable from '@cargo/loadable-components'
import { matchPath, __RouterContext } from "react-router";
import { withRouter, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import { actions } from "../actions";
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { isServer } from "@cargo/common/helpers";
import { store } from '../index';

/* Route components */
import Home from './home-page';
import InformationPageHandler from './information-page-handler';
import Templates from "./templates";
import Community from "./community";
import CommunityMenu from './community-menu';
import SitesList from "./siteslist";
import PublicFolder from './public-folder';
import SitePreviewer from "./site-previewer";
import FontsPage from './fonts-page';
import NewsletterProxy from './legacy-newsletter-proxy';
import WritingPage from './writing-page';

import JobView from '@cargo/common/cargo-jobs/job-view';
import AllJobs from '@cargo/common/cargo-jobs/all-jobs';

import { Login } from "@cargo/common/login";

export const staticPageComponents = {
	'students': Loadable({ loader: () => import('./static-pages/students')}),
	// 'shops': Loadable({ loader: () => import('./static-pages/shops')}),
	'terms': Loadable({ loader: () => import('./static-pages/terms')}),
	'privacy': Loadable({ loader: () => import('./static-pages/privacy')}),
	'data-processing-agreement': Loadable({ loader: () => import('./static-pages/data-processing')}),
	'copyright-issues': Loadable({ loader: () => import('./static-pages/copyright-issues')}),
	'information': Loadable({ loader: () => import('./static-pages/information')}),
}

export const staticPageTitles = {
	'students': 'Cargo: Students',
	'terms': 'Cargo: Terms of Service',
	'privacy': 'Cargo: Privacy Policy',
	'copyright-issues': 'Cargo: Copyright Issues',
};

export const paths = {
	ROOT: '/',
	LOGIN: '/login',
	//STATIC_PAGES: `/:page(${Object.keys(staticPageComponents).join('|')}|00[1-9]|0[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-4])/:param1?`,
	STATIC_PAGES: `/:page(${Object.keys(staticPageComponents).join('|')})/:param1?`,
	FOLDER: '/:folder',
	PUBLIC_FOLDER: '/folder/:userid/:folder',
	PUBLIC_FOLDER_PREVIEW: '/folder/:userid/:folderSlug/preview/:siteid',
	USEFUL_MUSIC: '/useful-music-:edition',
	COMMUNITY: '/community/:category',
	TEMPLATES: '/templates/:folder?',
	DESIGN_LAB: '/designlab/:folder?',
	FONTS: '/fonts',
	PREVIEW: '/:origin(templates)/preview/:siteid',
	PREVIEW_ROOT: '/:folder*/preview/:siteid',
	ACCOUNT: '/account/:path?/:param1?/:param2?',
	RATES: '/rates',
	JOBS: '/jobs',
    JOBVIEW: '/jobs/:jobId',
	WRITING: '/writing',
	WRITING_CATEGORY: '/writing/:category/:articleId?',
}

export const routeTitles = {
	[paths.COMMUNITY]: 'Cargo: Community',
	[paths.FONTS]: 'Cargo: Fonts',
	[paths.JOBS]: 'Cargo: Jobs',
	[paths.JOBVIEW]: 'Cargo: Jobs',
	[paths.STATIC_PAGES]: 'Cargo: Information',
	[paths.TEMPLATES]: 'Cargo: Templates',
	[paths.WRITING]: 'Cargo: Writing',
	[paths.WRITING_CATEGORY]: 'Cargo: Writing',
};

export const getRouteInfo = ({match}) => {

	const state = store.getState();

	return {
		isTemplates: match.path === paths.TEMPLATES,
		isCommunity: match.path === paths.COMMUNITY,
		isHomePage: match.path === paths.ROOT,
		isStaticPage: match.path === paths.STATIC_PAGES,
		isFonts: match.path === paths.FONTS,
		isPublicFolder: match.path === paths.PUBLIC_FOLDER,
		isJobs: match.path === paths.JOBS,
		isJobsPreview: match.path === paths.JOBVIEW || match.path === paths.JOBVIEW_ROOT,
		isUserFolder: match.path === paths.FOLDER || ( match.path === paths.ROOT && state.auth.authenticated ),
		isWriting: match.path === paths.WRITING || match.path === paths.WRITING_CATEGORY,
	}

}

const previewOverlayJSX = ( match, isPublicFolder ) => {

	return( 
		<SitePreviewer 
			siteToPreview={match.params.siteid}
			fromPublicFolder={isPublicFolder}
		/>
	)
}

class AccountManagerRouteComponent extends Component {

	constructor(props) {
		super(props);

		this.AccountManagerWindow = Loadable({ loader: () => import('./account-manager-window')});
	}

	render() {
		return null;
	}

	componentDidMount() {

		if(!this.props.authenticated) {
			return false
		}

		if(!this.props.activeUIWindows.hasOwnProperty('c3-account-manager-window')) {

			this.props.addUIWindow({
				group: 'overlay',
				component: () => <__RouterContext.Consumer>
					{routerState => {
						return <__RouterContext.Provider value={this.props.context}>
							<this.AccountManagerWindow></this.AccountManagerWindow>
						</__RouterContext.Provider>
					}}
				</__RouterContext.Consumer>,
				id: `c3-account-manager-window`,
				props: {
					type: 'popover', 
					positionType: 'center', 
					windowName: 'c3-account-manager',
					clickoutLayer: true,
					clickoutLayerDim: true,
					draggingUploadedImage: false,
					disableDragging: false,
					preventClickout: true
				}
			});

		}

	}

	componentWillUnmount() {

		// remove all UI windows
		this.props.removeUIWindow(uiWindow => {
			if( !uiWindow.props.clickoutLayer && !uiWindow.props.preventClickout ){
				return false;
			}
			return true;
		});

	}

}

const AccountManagerRoute = connect(
	(state, ownProps) => ({
		authenticated: state.auth.authenticated,
		activeUIWindows: state.uiWindows.byId
	}), 
	dispatch => bindActionCreators({
		addUIWindow: actions.addUIWindow,
		updateUIWindow: actions.updateUIWindow,
		removeUIWindow: actions.removeUIWindow
	}, dispatch)
)(AccountManagerRouteComponent)

export const routes = [
	{
		path: paths.USEFUL_MUSIC,
		requireLogin: false,
		redirectPath: match => {
			return `https://usefulmusic.cargo.site/Useful-Music-${match.params.edition}`
		},
		render: function({ layer }) {
			window.location.href = this.redirectPath(layer.match);
			return null;
		}
	},
	{
		path: paths.PUBLIC_FOLDER_PREVIEW,
		requireLogin: false,
		isOverlay: true,
		basePath: (layer) => {
			return layer.location.pathname.replace(/\/preview\/[\w\d]+\/?$/, '') || '/'
		},
		render: function({ layer }) {
			return previewOverlayJSX(layer.match, true)
		}
	},
	{
		path: paths.PREVIEW,
		requireLogin: false,
		isOverlay: true,
		basePath: (layer) => {
			return layer.location.pathname.replace(/\/preview\/[\w\d]+\/?$/, '') || '/'
		},
		render: function({ layer }) {
			return previewOverlayJSX(layer.match, false)
		}
	},
	{
		path: paths.PREVIEW_ROOT,
		requireLogin: true,
		isOverlay: true,
		basePath: (layer) => {
			return layer.location.pathname.replace(/\/preview\/[\w\d]+\/?$/, '') || '/'
		},
		render: function({ layer }) {
			return previewOverlayJSX(layer.match, false)
		}
	},
	// Special SSR intervention to not return 404 when hitting
	// an /account path. Fastify route matching cannot do multiple
	// optional parameters so we have to define 3 routes to catch everything
	{
		path: '/account/:path?',
		requireLogin: false,
		isOverlay: false,
		ssrOnly: true,
		render: () => null
	},
	{
		path: '/account/:path/:param1/:param2?',
		requireLogin: false,
		isOverlay: false,
		ssrOnly: true,
		render: () => null
	},
	{
		path: paths.ACCOUNT,
		requireLogin: true,
		isOverlay: true,
		render: function({layer, context, props, router}) {

			const authenticated = store.getState().auth.authenticated;

			if(authenticated) {
				return <AccountManagerRoute context={context} />
			} else {
				return <Login 
					getAuthTokenAction={actions.getAuthToken}
					canCreateNewAccount={false}
				/>
			}

		},
		mapStateToProps: state => ({
			authenticated: state.auth.authenticated
		})
	},
	{
		path: paths.LOGIN,
		requireLogin: false,
		isOverlay: false,
		render: function({layer, context, props, router}) {
			return <Login 
				getAuthTokenAction={actions.getAuthToken}
				canCreateNewAccount={false}
				onLoginSuccess={data => {
					router.props.history.replace(`/`);
				}}
			/>
		},
		renderStandalone: true
	},
	{
		path: paths.JOBVIEW,
		requireLogin: false,
		isOverlay: false,
		render: function({ layer }) {
			return <JobView actions={actions} />
		}
	},
	{
        path: paths.JOBS,
        requireLogin: false,
        isOverlay: false,
        render: function({ layer }) {
            return <AllJobs actions={actions} />
        }
    },
	{
		path: paths.WRITING,
		requireLogin: false,
		isOverlay: false,
		render: function({layer, context, routeProps, router}) {
			return <WritingPage actions={actions} />
		}
	},
	{
		path: paths.WRITING_CATEGORY,
		requireLogin: false,
		isOverlay: false,
		render: function({layer, context, routeProps, router}) {
			return <WritingPage actions={actions} />
		}
	},
	{
		path: paths.STATIC_PAGES,
		requireLogin: false,
		get isOverlay() {

			const routeProps = this.mapStateToProps(store.getState());

			if(routeProps.authenticated) {
				// when in authed homepage we want to overlay
				return true;
			}

			return false;
		},
		render: function({ layer }) {

			if(layer.match.params.page) {

				const newsletterEditionAsInt = parseInt(layer.match.params.page);

				if(
					newsletterEditionAsInt
					&& newsletterEditionAsInt >= 1
					&& newsletterEditionAsInt <= 254
				) {
					if(this.props.authenticated) {
						return <SitesList />
					} else {
						return <NewsletterProxy edition={layer.match.params.page} />
					}
				}
			}

			return <InformationPageHandler />
		},
		mapStateToProps: state => ({
			authenticated: state.auth.authenticated
		})
	},
	{
		path: paths.RATES,
		requireLogin: false,
		isOverlay: false,
		redirectPath: match => {
			return `/information`
		},
		render: function({ layer }) {
			return <Redirect to="/information" />;
		}
	},
	{
		path: paths.FONTS,
		requireLogin: false,
		isOverlay: false,
		render: function({ layer }) {
			return <FontsPage />
		}
	},
	{
		path: paths.TEMPLATES,
		requireLogin: false,
		isOverlay: false,
		render: function({ layer }) {
			return <Templates />
		}
	},
	{
		path: paths.DESIGN_LAB,
		requireLogin: false,
		isOverlay: false,
		redirectPath: match => {
			return `/templates`
		},
		render: function({ layer }) {
			return <Redirect to="/templates" />;
		}
	},
	{
		path: paths.COMMUNITY,
		isOverlay: false,
		render: function({layer, context, routeProps, router}) {

			const communityMatch = matchPath(router.props.location.pathname, { path: paths.COMMUNITY, exact: true });

			if( 
				routeProps.isMobile 
				&& communityMatch 
				&& communityMatch.params.category !== 'all'
			){
				return <Redirect to="/community/all" />;
			}

			return (

				<Community isMobile={routeProps.isMobile}>
					{ routeProps.isMobile ? null: <CommunityMenu history={router.props.history}/>}
				</Community>

			)
		},
		mapStateToProps: state => ({
			isMobile: state.homepageState.isMobile
		})
	},
	{
		path: paths.PUBLIC_FOLDER,
		requireLogin: false,
		isOverlay: false,
		render: function({ layer }) {
			return <PublicFolder />
		}
	},
	{
		path: paths.FOLDER,
		requireLogin: true,
		isOverlay: false,
		render: function({ layer }) {
			return <SitesList />
		},
	},
	{
		path: paths.ROOT,
		requireLogin: false,
		isOverlay: false,
		render: function({ layer, routeProps }) {

			if(routeProps.authenticated) {
				// render user sites
				return <SitesList />
			} else {
				return (
					<div id="home-page">
						<Home />
						{routeProps.isMobile ? <Community isMobile={true} /> : null}
					</div>
				)
			}

		},
		mapStateToProps: state => ({
			authenticated: state.auth.authenticated,
			isMobile: state.homepageState.isMobile
		})
	},
	{
		path: '*',
		requireLogin: false,
		isOverlay: false,
		render: function({ layer }) {
			if(!this.lockedUnderlayLocation) {
				// no overlay nor underlay route has been matched.
				return <Redirect to="/" />;
			}
		}
	}
]

export const routesByPath = _.keyBy(routes, 'path');
export const routesInUse = routes.map(route => _.pick(route, ['path', 'redirectPath', 'requireLogin']));